<template>
  <v-app id="app">
    <!-- <v-navigation-drawer
      width="210"
      style="background: #dfdfdf;"
      app
      v-model="drawer"
    >
      <h3 class="menu-title">CRM</h3>
      <v-list nav dense>
        <template v-for="item in items">
          <v-list-item-group v-if="!item.divider" :key="item.text">
            <v-list-item :to="item.link" link exact>
              <v-icon left>{{ item.icon }}</v-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider v-if="item.divider" :key="item.text" class="my-4" />
        </template>
      </v-list>
    </v-navigation-drawer> -->
    <!-- <v-app-bar
      flat
      app
      style="border-bottom: 1px solid #f0f0f0 !important;"
      color="white"
    >
      <div class="wrapper">
        <div class="top-left">
          <v-app-bar-nav-icon @click="drawer = !drawer" />
          <portal-target name="destination"></portal-target>
        </div>
        <div class="top-right">
          <portal-target name="cto-topmenu"></portal-target>
          <v-icon class="ml-4" left>mdi-magnify</v-icon>
          <v-chip class="ma-2 pl-5 pr-5" color="#dfdfdf">
            <v-icon small>mdi-account-circle-outline</v-icon>
            <p class="pl-1 pr-1 business-sector">{{ businessSector }}</p>
          </v-chip>
        </div>
      </div>
    </v-app-bar> -->
    <v-main>
      <v-container fluid>
        <router-view :key="appVersion" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import EventBus from './plugins/event-bus'
import businessSector from './index'

export default {
  data() {
    return {
      appVersion: 1,
      drawer: true,
      left: true,
      businessSector: businessSector,
      items: [
        {
          text: 'Klientët',
          link: '/clients',
          icon: 'mdi-account-group-outline',
        },
        {
          text: 'Furnitorët',
          link: '/suppliers',
          icon: 'mdi-account-multiple-outline',
        },
        {
          text: 'Kontaktet',
          link: '/contacts',
          icon: 'mdi-contacts-outline',
        },
        {
          text: 'Kartela e Klientit',
          link: '/clients/journal',
          icon: 'mdi-folder-account-outline',
        },
        {
          text: 'Kartela e Furnitorit',
          link: '/suppliers/journal',
          icon: 'mdi-card-account-details-outline',
        },
      ],
    }
  },
  mounted() {
    EventBus.$on('close_navigation_drawer', () => {
      this.drawer = false
    })
    EventBus.$on('change_sector', () => {
      this.appVersion++
      console.log(this.appVersion)
    })
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.top-left {
  display: flex;
  align-items: center;
}
.top-right {
  display: flex;
  align-items: center;
}
.business-sector {
  padding-top: 15px;
  font-weight: 500;
}
.app-title {
  padding-left: 15px;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}
.menu-title {
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 0px 0px 0px 15px;
  height: 64px;
  padding: 0px;
}
</style>
