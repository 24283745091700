<template>
  <div class="all">
    <header>
      <div class="logo-container">
        <img class="logo" src="../../public/img/Logo.png" alt="Logo" />
      </div>
      <nav>
        <v-btn text class="custom-btn">Home</v-btn>
        <v-btn text class="custom-btn">About</v-btn>
        <v-btn text class="custom-btn"> Contact</v-btn>
      </nav>
    </header>
    <div class="image-container">
      <img
        src="../../public/img/phone.png"
        alt="Your Image"
        class="cover-image"
      />
    </div>

    <main>
      <div class="introduction-container ">
        <h1>
          Elevate Your Store Identity and
          <span>Spark Your Business</span>
        </h1>
        <div class="introduction">
          <p class="introduction-web">
            Craft a Unique Online Presence in Minutes to Exceed All Your
            Clients' Needs and Requirements. Revolutionize Your E-commerce Game.
            Elevate Your Brand, Boost Sales, and Exceed <br />Expectations, All
            in Minutes!"
          </p>
          <div class="introduction-mobile">
            <p>
              Craft a Unique Online Presence in Minutes to Exceed All Your
              Clients' Needs and Requirements.
            </p>
            <v-divider />
            <p>
              Revolutionize Your E-commerce Game. Elevate Your Brand, Boost
              Sales, and Exceed <br />Expectations, All in Minutes!"
            </p>
          </div>
          <div class="introduction-image-container">
            <img
              class="introduction-image"
              src="../../public/img/Group 41 (1).png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="store" v-if="!showForm && !registered">
        <h1>Create your store</h1>
        <p class="ml-4">Chose your domain</p>
        <v-text-field
          v-model="domainName"
          color="rgba(0, 0, 0, 0.87)"
          outlined
          rounded
          dense
          suffix=".yourshop.al"
          placeholder="Enter your domain..."
          :error-messages="errorMessages"
          @input="clearErrorMessage"
        />
        <v-btn
          style="color: #ffffff"
          width="200px"
          dark
          class="text-primary"
          rounded
          depressed
          color="rgba(77, 217, 100, 1)"
          @click="lookupdomainName"
        >
          Create</v-btn
        >
      </div>

      <div class="submit-info" v-if="showForm && !registered">
        <v-form ref="form" v-model="valid" lazy-validation>
          <p>
            Congrats! <b>{{ domainName }}.yourshop.al</b> is available!
            <br />
            Complete the form below to reserve it now.
          </p>

          <v-text-field
            v-model="name"
            placeholder="Enter your name here..."
            color="rgba(0, 0, 0, 0.15)"
            outlined
            rounded
            solo
            dense
            :rules="nameRules"
          />

          <v-text-field
            v-model="phone"
            placeholder="Enter your phone number here..."
            color="rgba(0, 0, 0, 0.15)"
            outlined
            rounded
            solo
            dense
            :rules="phoneRule"
          />

          <v-text-field
            v-model="email"
            color="rgba(0, 0, 0, 0.15)"
            outlined
            rounded
            solo
            dense
            placeholder="Enter your email here..."
            :rules="emailRules"
          />

          <div class="new-button">
            <v-btn color="success" class="mr-3" @click="submitForm()">
              Submit
            </v-btn>
            <v-btn style="color: #ffffff;" color="#656565 " @click="goBack()">
              Go Back
            </v-btn>
          </div>
        </v-form>
      </div>

      <div class="submit-info" v-if="registered">
        <p>
          <b>
            Welcome! {{ domainName }}.yourshop.al is now reserved!
            <br />
            We will contact you shortly.
          </b>
        </p>

        <div class="new-button">
          <v-btn style="color: #ffffff;" color="#656565 " @click="goBack()">
            Go Back
          </v-btn>
        </div>
      </div>

      <div class="website-collection">
        <h1>Take the First Step Todday</h1>
        <img class="website-image" src="../../public/img/all.png" alt="" />
      </div>

      <div class="business-plan">
        <h1>The Right Plan for Your Business</h1>
        <p>
          We have several powerful plans to showcase your business and get
          discovered as a creative entrepreneurs. Everything you need.
        </p>

        <v-btn-toggle class="my-4" v-model="activeButton" mandatory>
          <v-btn
            value="1"
            depressed
            :color="activeButton === '1' ? 'rgba(77, 217, 100, 1)' : 'grey'"
            class="text-primary  pa-0 btn-togle "
          >
            Monthly
          </v-btn>
          <v-btn
            value="2"
            depressed
            :color="activeButton === '2' ? 'rgba(77, 217, 100, 1)' : 'grey'"
            class="text-primary btn-togle"
          >
            Annualy
          </v-btn>
        </v-btn-toggle>

        <div>
          <v-row class="my-4">
            <v-col
              class="show-card"
              v-for="(plan, index) in plans"
              :key="index"
              cols="12"
              sm="6"
              md="4"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  height="500px"
                  class="card-style pa-4 "
                  :elevation="hover ? 12 : 7"
                  :class="{ 'on-hover': hover }"
                  outlined
                >
                  <v-card-title>{{ plan.price }}</v-card-title>
                  <v-card-text v-for="(item, index) in plan.text" :key="index">
                    <div
                      class="on-hover-active"
                      :class="item.status === 1 ? 'active' : 'deactive'"
                    >
                      <v-icon
                        size="20px"
                        :color="item.status === 1 ? 'black' : 'grey'"
                      >
                        {{ item.icon }}</v-icon
                      >
                      <span>
                        {{ item.name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-card-actions class="flex-grow-1 align-end">
                    <v-btn text color="green">Choose</v-btn>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>

            <v-carousel
              class="show-cardMobile"
              hide-delimiters
              :show-arrows="false"
              :continuous="false"
              v-model="currectSlide"
            >
              <v-carousel-item v-for="(plan, index) in plans" :key="index">
                <v-col cols="12" sm="6" md="4">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      height="450px"
                      class="card-style pa-3 "
                      :elevation="hover ? 12 : 7"
                      :class="{ 'on-hover': hover }"
                      outlined
                    >
                      <v-card-title>{{ plan.price }}</v-card-title>
                      <v-card-text
                        v-for="(item, index) in plan.text"
                        :key="index"
                      >
                        <div
                          class="on-hover-active"
                          :class="item.status === 1 ? 'active' : 'deactive'"
                        >
                          <v-icon
                            size="20px"
                            :color="item.status === 1 ? 'black' : 'grey'"
                          >
                            {{ item.icon }}</v-icon
                          >
                          <span>
                            {{ item.name }}
                          </span>
                        </div>
                      </v-card-text>
                      <v-card-actions class="flex-grow-1 align-end">
                        <v-btn text color="green">Choose</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </div>
      </div>
    </main>
    <footer>
      <p>Spark Store 2023 &copy; All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  data() {
    return {
      domainName: '',
      showMessage: '',
      showForm: false,
      registered: false,
      showMenu: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      name: '',
      phone: '',
      email: '',
      hideText: false,
      msg: [],
      activeButton: '1',
      valid: true,
      errorMessages: [],
      nameRules: [v => !!v || 'Emri është i  nevojshëm'],
      phoneRule: [
        v => !!v || 'Please enter your  phone number',
        v => (v && v.length == 10) || 'Phone number must be 10 digits long',
      ],
      emailRules: [
        v => !!v || 'Please enter your  email',
        v => /.+@.+\..+/.test(v) || 'Invalid Email Address',
      ],
      plans: [
        {
          price: 'Free',
          text: [
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-close',
              status: 0,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-close',
              status: 0,
            },
          ],
        },
        {
          price: '20$',
          text: [
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-close',
              status: 0,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-close',
              status: 0,
            },
          ],
        },
        {
          price: '30$',
          text: [
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-check',
              status: 1,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-close',
              status: 0,
            },
            {
              name: 'Upload Video up to 720p Resulutions ',
              icon: 'mdi-close',
              status: 0,
            },
          ],
        },
      ],
    }
  },
  mounted() {},
  watch: {
    domainName: function() {
      if (this.domainName.length == 0) {
        this.showMessage = ''
      }
    },
    phone(value) {
      this.phone = value
      this.checkPhoneLength(value)
    },
    email(value) {
      this.email = value
      this.validateEmail(value)
    },
  },
  methods: {
    lookupdomainName: _.debounce(function() {
      var app = this
      if (app.domainName === '') {
        app.errorMessages = ['Please enter a domain']
        return
      }

      axios
        .get(
          `${this.baseUrl}/api/multi-tenant-management/domain-reservation?domain=${this.domainName}`
        )
        .then(function(response) {
          if (response.data.status != 0) {
            app.errorMessages = response.data.message
          } else {
            app.showForm = true
            app.hideText = ''
          }
        })
        .catch(function(error) {
          alert(error)
        })
    }, 500),
    submitForm() {
      if (!this.$refs.form.validate()) {
        return false
      }

      const payload = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        domain: this.domainName,
      }

      axios
        .post(
          `${this.baseUrl}/api/multi-tenant-management/domain-reservation`,
          payload
        )
        .then(response => {
          this.registered = true
          this.sendEmail()
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    checkPhoneLength() {
      if (this.phone.length < 10) {
        return 'Phone number must be 10 digits long'
      } else {
        return true
      }
    },

    clearErrorMessage() {
      this.errorMessages = []
    },
    goBack() {
      this.registered = false

      this.name = ''
      this.phone = ''
      this.email = ''

      this.showForm = false
      this.domainName = ''
    },
    validateEmail(value) {
      let url = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ //eslint-disable-line
      if (this.email && !url.test(value)) {
        return 'Invalid Email Address'
      } else {
        return true
      }
    },
    sendEmail() {
      const payload = {
        domain: this.domainName,
      }
      axios
        .post(
          `${this.baseUrl}/api/multi-tenant-management/send-domain-reservation`,
          payload
        )
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.all {
  width: 90%;
  margin: auto;
  padding-top: 10px;
}

.btn-togle {
  border-radius: 32px !important;
  width: 150px;
  font-size: 12px !important;
  text-transform: none !important;
  color: #ffffff !important;
  height: 35px !important;
}

.active {
  color: black;
}
.deactive {
  color: grey;
}
.new-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  width: 250px;
}
.logo-container img {
  width: 100%;
  height: 100%;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.introduction-container {
  display: flex;
  flex-direction: column;
  margin: 5% 0;
}

.introduction {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.introduction-container h1 {
  font-family: 'Archivo Black';
  font-weight: 400;
  width: 400px;
}
.introduction-container h1 span {
  color: #4dd964;
}
.introduction > p {
  font-family: 'Cabin';
  font-weight: 700;
  color: rgba(0, 0, 0, 0.44);
  width: 750px;
  margin-top: 20px;
}

.introduction-image-container {
  display: none;
}

.introduction-mobile {
  display: none;
}

.store {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}
.store > h1 {
  font-family: 'Archivo Black';
  font-weight: 400;
  color: rgba(70, 70, 70, 1);
}
.store > p {
  font-family: 'Archivo Black';
  font-weight: 400;
  color: rgba(0, 0, 0, 0.42);
}

.submit-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}

.image-container {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  height: 30%;
  width: 30%;
}

.cover-image {
  width: 100%;
  max-height: 100%;
}

.website-collection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}
.website-collection h1 {
  font-family: 'Archivo Black';
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
}
.website-image {
  max-width: 100%;
  max-height: 100%;
}

.business-plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}
.business-plan h1 {
  width: 500px;
  font-family: 'Inter';
  font-weight: 700;
}

.business-plan p {
  width: 500px;
}
footer {
  width: 100%;
  text-align: center;
}

.card-style {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.card-style.v-sheet.v-card {
  border-radius: 16px !important;
}

.show-cardMobile {
  display: none;
}

@media only screen and (max-width: 900px) {
  .all {
    width: 100%;
  }
  header {
    width: 100%;
  }
  .logo-container {
    width: 100px;
  }
  .custom-btn {
    padding-left: 0px !important;
  }
  .image-container {
    display: none;
  }
  .introduction-container {
    display: flex;
    flex-direction: column;
  }
  .introduction-container h1 {
    font-family: 'Archivo Black';
    font-weight: 400;
    width: 100%;
    padding: 10% 0;
  }
  .introduction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10% 0;
  }
  .introduction-web {
    display: none;
  }

  .introduction-mobile > p {
    font-family: 'Cabin';
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.44);
  }
  .introduction-image-container {
    display: flex;
    margin-right: -12px;
  }
  .introduction-image-container img {
    width: 100%;
  }

  .introduction-mobile {
    height: 250px;
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .logo-container {
    width: 100px;
  }

  .website-collection h1 {
    font-size: 30px;
  }

  .business-plan h1 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 25px;
    width: auto;
  }

  .business-plan p {
    width: auto;
  }

  .show-card {
    display: none;
  }
  .show-cardMobile {
    display: flex;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
}
</style>
