import Vue from 'vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import App from './Index.vue'
import LiquorTree from 'liquor-tree'
import './assets/css/liquor_tree.css'
import './assets/css/general.css'
import PortalVue from 'portal-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

Vue.use(PortalVue)

Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

Vue.use(LiquorTree)

export const bus = new Vue()

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
